import styled from 'styled-components';
import {device} from "./device"

export const Commonbtn = styled.a`
    font-size:14px;
    background: #f26522;
    color: #fff;
    padding: 8px 40px;
    border-radius: 50px;
    text-decoration:none;
    font-weight:bold;
    display: inline-block;
    border-radius:50px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    ::before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius:50px;
        background: #a100f2;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    :hover::before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    :hover
    {
        color: #fff;
        text-decoration:none;
    }
`;


export const CommonSubmitbtn = styled.button`
    font-size:14px;
    background: #f26522;
    color: #fff;
    padding: 8px 40px;
    border-radius: 50px;
    text-decoration:none;
    font-weight:bold;
    display: inline-block;
    border-radius:50px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    ::before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius:50px;
        background: #a100f2;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    :hover::before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    :hover
    {
        color: #fff;
        text-decoration:none;
    }
`;
export const SectionHeading = styled.h3`
    font-size:30px;
    color:#262626;
    text-align:center;
    font-weight:500;
    margin-bottom:10px;
    line-height:1;
    
    @media ${device.tablet} {
        margin-bottom:0px;
    }
`;


export const SectionSubHeading = styled.p`
    font-size:16px;
    color:#424345;
    text-align:center;
    font-weight:400;
`;
