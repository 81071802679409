import React from 'react';
import {OurProductSection,ProductsRightHeading,
    ProductsHeading,ProductsInner,ProductsLeft,ProductsRight,ProductsLeftHeading,
    ProductsRightImgFirst,ProductsLeftImgFirst,ProductsLeftImgSecond,
    ProductsRightImgSecond,ProductsBtn,ProductsRowReverse,ProductsRow
} from './OurProduct.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Product  = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saas2Json {
                Product {
                    DelayConstant
                    ProductHeading
                    ProductRow1{
                        ProductRightImgFirst
                        ProductRightImgSecond
                        ProductHeading
                        ProductDescription
                        ProductBtn
                    }
                    Row{
                        ProductLeftImgFirst
                        ProductLeftImgSecond
                        ProductHeading
                        ProductDescription
                        ProductBtn
                    }
                    ProductRow2{
                        ProductRightImgFirst
                        ProductRightImgSecond
                        ProductHeading
                        ProductDescription
                        ProductBtn
                    }
                }
            }
        }
    `);
    return (
        <OurProductSection id="ProductContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.saas2Json.Product.DelayConstant}>
                            <ProductsHeading>{JSONData.saas2Json.Product.ProductHeading}</ProductsHeading>
                        </Fade>
                    </Col>
                </Row>

                <ProductsInner>

                    <ProductsRowReverse padddingBottom={"80px"}>
                        <Col md="7">
                            <Fade right delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saas2Json.Product.ProductRow1.ProductRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saas2Json.Product.ProductRow1.ProductRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>

                        <Col md="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsLeftHeading>
                                    {JSONData.saas2Json.Product.ProductRow1.ProductHeading}
                                    </ProductsLeftHeading>
                                </Fade>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <p>
                                    {JSONData.saas2Json.Product.ProductRow1.ProductDescription}
                                    </p>
                                </Fade>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saas2Json.Product.ProductRow1.ProductBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>

                    <ProductsRow>
                        <Col md="7">
                        <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                            <ProductsRight style={{textAlign:"left"}}>
                                <ProductsLeftImgFirst src={JSONData.saas2Json.Product.Row.ProductLeftImgFirst} alt=""/>
                                <ProductsLeftImgSecond src={JSONData.saas2Json.Product.Row.ProductLeftImgSecond} alt=""/>
                            </ProductsRight>
                            </Fade>
                        </Col>
                        <Col md="5">
                            <ProductsLeft>
                                <Fade right delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsRightHeading>
                                    {JSONData.saas2Json.Product.Row.ProductHeading}
                                    </ProductsRightHeading>
                                </Fade>
                                <Fade right delay={2*JSONData.saas2Json.Product.DelayConstant}>
                                    <p>
                                    {JSONData.saas2Json.Product.Row.ProductDescription}
                                    </p>
                                </Fade>
                                <Fade right delay={3*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saas2Json.Product.Row.ProductBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRow>

                    <ProductsRowReverse padddingBottom={"0px"}>
                        <Col md="7">
                            <Fade right delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                <ProductsRight>
                                    <ProductsRightImgFirst src={JSONData.saas2Json.Product.ProductRow2.ProductRightImgFirst} alt=""/>
                                    <ProductsRightImgSecond src={JSONData.saas2Json.Product.ProductRow2.ProductRightImgSecond} alt=""/>
                                </ProductsRight>
                            </Fade>
                        </Col>
                        <Col md="5">
                            <ProductsLeft>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsLeftHeading>
                                    {JSONData.saas2Json.Product.ProductRow2.ProductHeading}
                                    </ProductsLeftHeading>
                                </Fade>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <p>
                                    {JSONData.saas2Json.Product.ProductRow2.ProductDescription}
                                    </p>
                                </Fade>
                                <Fade left delay={1*JSONData.saas2Json.Product.DelayConstant}>
                                    <ProductsBtn href="#">
                                    {JSONData.saas2Json.Product.ProductRow2.ProductBtn}
                                    </ProductsBtn>
                                </Fade>
                            </ProductsLeft>
                        </Col>
                    </ProductsRowReverse>
                </ProductsInner>
            </Container>
        </OurProductSection>
    );
}
export default Product;
