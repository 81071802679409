import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {device} from '../Common/device'

export const ProductOverviewSection = styled.section`
    padding:100px 0px;
    background:#f9fafc;
    position:relative;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const ProductOverviewCustomContainer = styled.div`
`;

export const ProductOverviewSectionHeading = styled(SectionHeading)`
`;

export const ProductOverviewCol = styled.div`
    text-align:center;
    padding:60px 20px 0px;

    h5{
        line-height:1;
        margin-bottom:12px;

    }
    img{
        height:75px;
        margin-bottom:25px;
    }
    p{
        margin-bottom:5px;
    }
`;
