import {createGlobalStyle} from 'styled-components';
import {device} from '../Common/device'

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Lato', sans-serif;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        color:#424345;
        margin-bottom:20px;
    }
    a{
        font-family: 'Lato', sans-serif;
        font-size:16px;
        text-decoration:none;
        :hover{
            text-decoration:none;
        }
    }
    h1{
        font-size:43px;
        line-height:49px;

    }
    h2{
        font-size:36px;
        line-height:42px;

        @media ${device.laptop} {
            font-size: 32px;
            line-height: 38px;
        }
    }
    h3{
        font-size:30px;
        line-height:36px;

        @media ${device.laptop} {
            font-size: 26px;
            line-height:32px;
        }
    }
    h4{
        font-size:26px;
        line-height:32px;

        @media ${device.laptop} {
            font-size:24px;
            line-height:30px;
        }
    }
    h5{
        font-size:24px;
        line-height:30px;

        @media ${device.laptop} {
            font-size:20px;
            line-height:26px;
        }
    }
    h6{
        font-size:18px;
        line-height:24px;
    }
    img{
        margin-bottom:0px;
    }
    p{
        margin-bottom:20px;
        color:#7a7f83;
        font-weight:400;
        line-height:25px;
        font-size:14px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
