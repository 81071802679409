import React from "react";
import Banner from "../containers/Saas2/Banner";
import HeaderMenuBS from "../containers/Saas2/HeaderMenuBS";
import ProductOverview from "../containers/Saas2/ProductOverview";
import OurProduct from "../containers/Saas2/OurProduct";
import Performance from "../containers/Saas2/Performance";
import Pricing from "../containers/Saas2/Pricing";
import Footer from "../containers/Saas2/Footer";
import Faq from "../containers/Saas2/Faq";
import Testimonials from "../containers/Saas2/Testimonials";
import GlobalStyle from "../containers/Saas2/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Saas2/Common/fonts"
import BuyNow from "../components/BuyNow";

const IndexPage = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenuBS/>
       <main>
            <Seo 
                title="Saas 2 ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Saas 2 Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <ProductOverview/>
            <OurProduct/>
            <Performance/>
            <Faq/>
            <Pricing/>
            <Testimonials/>
        </main>
       <Footer/>
   </div>

)

export default IndexPage;
