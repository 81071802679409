import styled from 'styled-components';
import {Commonbtn} from '../Common/common.style'
import {device} from '../Common/device'
import {Play} from '@styled-icons/boxicons-regular/Play'

export const BannerSection = styled.section`
    padding:100px 0px;
    min-height:100vh;
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        background-size:cover;
        padding:80px 10px;
    }
`;

export const BannerContents = styled.div`
    padding-top:20px;
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        display:block;
        padding-top:30px;
    }
`;

export const BannerContentLeft = styled.div`

    h2{
        margin-bottom:7px;
        color:#272727;
    }

    h3{
        margin-bottom:30px;
    }

    p{
        margin-bottom:40px;
        opacity:0.8;
    }
    width:60%;
    padding-right:100px;

    @media ${device.laptop} {
        width:60%;
        padding-right:10px;
    }

    @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-right:0px;
    }
`;


export const BannerBtnsWrapper = styled.div`
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        justify-content:center;
    }

    @media ${device.mobileM} {
        flex-direction:column
    }
`;  

export const BannerBtn = styled(Commonbtn)`
    text-decoration:none;
`;

export const BannerVideoBtn = styled.a`
    margin-left:25px;
    text-decoration:none;
    color: #f26522;
    border: 1px solid #f26522;
    display:inline-flex !important;
    align-items:center;
    border-radius: 50px;
    transition:all .5s;
    padding:3px 20px;

    :hover{
        background:#f26522;
        color:#fff;
        transition:all .5s;
    }

    @media ${device.tablet} {
        margin-left:10px;
    }
    @media ${device.mobileM} {
        margin-left:0px;
        margin-top:20px;
    }

    p{
        margin-bottom:0;
        line-height:1;
        color:#272727;

        :hover{
            color:#fff;
        }
    }
`;

export const PlayIconCustom = styled(Play)`
    position: relative;
    vertical-align: middle;
    color: #f26522 !important;
    width: 30px;
    transition:all .5s;

    ${BannerVideoBtn}:hover &{
        color:#fff !important;
        transform: scale(1.1);
        transition:all .5s;
    }
`;

export const BannerContentRight = styled.div`
    width:40%;

    @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-top:45px;
    }
`;
