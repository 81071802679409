import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ProductOverviewSection,
    ProductOverviewSectionHeading,
    ProductOverviewCol,
    ProductOverviewCustomContainer} from './productoverview.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const ProductOverview = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            saas2Json {
                ProductOverview {
                    DelayConstant
                    SectionHeading
                    SectionSubHeading
                    ProductOverviewCustomContainer{
                        ProductOverviewIcon
                        ProductOverviewHeading
                        ProductOverviewDesc
                    }
                }
            }
        }
    `);
        return (
        <ProductOverviewSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={JSONData.saas2Json.ProductOverview.DelayConstant}>
                            <ProductOverviewSectionHeading>{JSONData.saas2Json.ProductOverview.SectionHeading}</ProductOverviewSectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <ProductOverviewCustomContainer>
                    <Row>
                    {
                        JSONData.saas2Json.ProductOverview.ProductOverviewCustomContainer.map((item,idx) => {
                        return <Col md="6" sm="12" lg="4">
                            <Fade bottom delay={(idx+1)*JSONData.saas2Json.ProductOverview.DelayConstant}>
                                <ProductOverviewCol>
                                    <img src={item.ProductOverviewIcon} alt=""/>
                                    <h5>{item.ProductOverviewHeading}</h5>
                                    <p>{item.ProductOverviewDesc}</p>
                                </ProductOverviewCol>
                            </Fade>
                        </Col>
                        })
                    }
                    </Row>
                </ProductOverviewCustomContainer>
            </Container>
        </ProductOverviewSection>
    );
}

export default ProductOverview;
